<template>
  <b-container fluid class="balance-report-over-time-total-chart-wrapper">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'balance-report-over-time-total-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportOverTimeTotalChart',
  props: ['apiData', 'currencySymbol', 'busy', 'chartType'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.balance.balance_report_over_time.chart.totals.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      // Custom chart options
      this.chartOptions.legend.display = false
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const suffix = ' ' + currencySymbol

      const sLabel = this.$t(this.translationPath + this.chartType + '_total_label', { currency: currencySymbol })

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const labelData = []
      const dataToDisplay = []
      let aAPIDataToDisplay = ''
      let primaryColor = ''

      switch (this.chartType) {
        case 'liability' :
          aAPIDataToDisplay = this.apiData.liability_balances
          primaryColor = '#dda3a2'
          break
        case 'asset' :
          aAPIDataToDisplay = this.apiData.asset_balances
          primaryColor = '#8cccad'
          break
        case 'networth' :
        default:
          aAPIDataToDisplay = this.apiData.networth
          primaryColor = '#b5c7e4'
      }

      for (var index in aAPIDataToDisplay) {
        dataToDisplay.push(aAPIDataToDisplay[index].toFixed(0))
        labelData.push(index)
      }

      const colors = dataToDisplay.map((value) => value < 0 ? '#dda3a2' : primaryColor)
      const dataset = {
        label: this.$t(this.translationPath + 'chart_label_' + sLabel),
        data: dataToDisplay,
        type: 'bar',
        borderColor: colors,
        backgroundColor: colors
      }

      this.chartData = {
        labels: labelData,
        datasets: [dataset]
      }
    }
  }
}
</script>

<style lang="scss">
  .balance-report-over-time-asset-chart-wrapper{

  }
</style>
