<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="networth" :class="{selected: activeTab === 'networth'}"  @click="onTabClick">
          {{ $t(translationPath + 'networthTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-totals" :class="{selected: activeTab === 'asset-totals'}"  @click="onTabClick">
          {{ $t(translationPath + 'assetTotalsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-groups" :class="{selected: activeTab === 'asset-groups'}"  @click="onTabClick">
          {{ $t(translationPath + 'assetGroupsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-subtypes" :class="{selected: activeTab === 'asset-subtypes'}"  @click="onTabClick">
          {{ $t(translationPath + 'assetSubtypesTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-accounts" :class="{selected: activeTab === 'asset-accounts'}"  @click="onTabClick">
          {{ $t(translationPath + 'assetAccountsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability-totals" :class="{selected: activeTab === 'liability-totals'}"  @click="onTabClick">
          {{ $t(translationPath + 'liabilitiesTotalsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability-groups" :class="{selected: activeTab === 'liability-groups'}"  @click="onTabClick">
          {{ $t(translationPath + 'liabilityGroupsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability-subtypes" :class="{selected: activeTab === 'liability-subtypes'}"  @click="onTabClick">
          {{ $t(translationPath + 'liabilitySubtypesTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability-accounts" :class="{selected: activeTab === 'liability-accounts'}"  @click="onTabClick">
          {{ $t(translationPath + 'liabilityAccountsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t('common.header.header_menu.user_menu.settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {
      default: 'reports.balance.balance_report_over_time.submenu.'
    },
    active: {
      type: String,
      default: 'networth'
    },
    currencySymbol: {
      type: String,
      default: 'kr'
    }
  },
  name: 'BalanceReportOverTimeSubmenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
.submenu-container {
  &.dark {
    .col-md-auto {
      padding-right: 0;
    }
  }
}
</style>
