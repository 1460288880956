<template>
  <div class="balance-report-over-time">

    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="7" md="8" lg="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
            </b-col>
            <b-col cols="12" sm="5" md="4" lg="3" class="pull-left pr-0 pl-0 pl-md-2" v-show="activeTab !== 'settings'">
              <period-selector
                :show-predefined-periods="true"
                :show-resolution="true"
                :default-start-date="start_date"
                :default-end-date="end_date"
                :default-resolution="resolution"
                :allowed-resolutions="['month', 'year']"
                @period-selected="applyFilter"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row
          :class="{'main-gradient-tab-menu-wrapper': true, 'mb-4': true, 'pb-5': activeTab === 'settings'}"
          no-gutters>
          <b-col no-gutters>
            <balance-report-over-time-submenu
              ref="Tabmenu"
              :apiData="apiData"
              :busy="busyState.chart"
              @tab-switched="onTabSwitched"
            ></balance-report-over-time-submenu>
          </b-col>
        </b-row>

      </b-container>

      <b-row class="main-gradient-chart-wrapper" :class="{'custom-pb-6': activeTab === 'income-expense'}" v-if="activeTab !== 'settings'">
        <div v-if="currentCOA.currency" class="pb-5" :style="{ width: '100%', height: '500px'}">
          <balance-report-over-time-total-chart :key="1" :apiData="apiData" v-if="activeTab === 'networth'" chartType="networth" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-total-chart>
          <balance-report-over-time-total-chart :key="2" :apiData="apiData" v-if="activeTab === 'asset-totals'" chartType="asset" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-total-chart>
          <balance-report-over-time-group-chart :key="3" :apiData="apiData" v-if="activeTab === 'asset-groups'" chartType="asset-groups" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>
          <balance-report-over-time-group-chart :key="4" :apiData="apiData" v-if="activeTab === 'asset-accounts'" chartType="asset-accounts" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>
          <balance-report-over-time-group-chart :key="5" :apiData="apiData" v-if="activeTab === 'asset-subtypes'" chartType="asset-subtypes" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>
          <balance-report-over-time-total-chart :key="6" :apiData="apiData" v-if="activeTab === 'liability-totals'" chartType="liability" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-total-chart>
          <balance-report-over-time-group-chart :key="7" :apiData="apiData" v-if="activeTab === 'liability-groups'" chartType="liability-groups" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>
          <balance-report-over-time-group-chart :key="8" :apiData="apiData" v-if="activeTab === 'liability-accounts'" chartType="liability-accounts" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>
          <balance-report-over-time-group-chart :key="9" :apiData="apiData" v-if="activeTab === 'liability-subtypes'" chartType="liability-subtypes" :busy="busyState.chart" :currency-symbol="currencySymbol"></balance-report-over-time-group-chart>

        </div>
      </b-row>

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0">
      <balance-report-over-time-total-table
        v-if="['networth', 'asset-totals', 'liability-totals'].indexOf(this.activeTab) !== -1"
        :apiData="apiData"
        tabType="total"
      ></balance-report-over-time-total-table>
      <balance-report-over-time-group-table
        v-if="['networth', 'asset-totals', 'liability-totals', 'settings'].indexOf(this.activeTab) === -1"
        :apiData="apiData"
        :tab="this.activeTab"
      ></balance-report-over-time-group-table>
      <!--SETTINGS COMPONENT-->
      <balance-report-over-time-settings
        v-if="activeTab === 'settings'"
        :sections="sections"
        @sections-update="handleSectionUpdate"
        @settings-updated="handleSettingsUpdate"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import BalanceReportOverTimeSubmenu from './BalanceReportOverTimeSubmenu'
import BalanceReportOverTimeTotalChart from './BalanceReportOverTimeTotalChart'
import BalanceReportOverTimeTotalTable from './BalanceReportOverTimeTotalTable'
import BalanceReportOverTimeGroupTable from './BalanceReportOverTimeGroupTable'
import BalanceReportOverTimeGroupChart from './BalanceReportOverTimeGroupChart'
import BalanceReportOverTimeSettings from './BalanceReportOverTimeSettings'
import _ from 'lodash'

export default {
  name: 'BalanceReportOverTime',
  mixins: [titleMixins],
  components: {
    BalanceReportOverTimeGroupChart,
    PeriodSelector,
    BalanceReportOverTimeSubmenu,
    BalanceReportOverTimeTotalChart,
    BalanceReportOverTimeTotalTable,
    BalanceReportOverTimeGroupTable,
    BalanceReportOverTimeSettings
  },
  data () {
    return {
      start_date: new Date(new Date().setMonth(new Date().getMonth() - 12)).toISOString().slice(0, 8).toString() + '01', // First day of this month a year ago
      end_date: new Date().toISOString().slice(0, 10), // Todays date
      apiData: [],
      resolution: 'month',
      activeTab: 'networth',
      dateRange: {},
      busyState: {
        chart: true,
        table: true
      },
      translationPath: 'reports.balance.balance_report_over_time.',
      sections: [
        {
          label: 'common.assets',
          description: 'common.settings.descriptions.asset', // Description under label (translation string: 'common.liabilities')
          model: 'asset',
          show: true,
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.liabilities',
          description: 'common.settings.descriptions.liability', // Description under label (translation string: 'common.liabilities')
          model: 'liability',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.title',
          model: 'title',
          required: true,
          value: null,
          disableButtons: true,
          child: [
            {
              type: 'input'
            }
          ]
        },
        {
          label: 'common.advanced_filter.description',
          model: 'description',
          value: null,
          show: true,
          disableButtons: true,
          child: [
            {
              type: 'textarea'
            }
          ]
        }
      ]
    }
  },
  async mounted () {
    this.changeTab(this.$route.params.tab)
    this.loadingDataToast()
    this.fetchApiData()
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'description', label: this.$t('common.description') },
        { key: 'action', label: this.$t('common.actions') }
      ]
    },
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('reports.balance.balance_report_over_time.title')
    },
    currencySymbol () {
      return this.currentCOA && Object.prototype.hasOwnProperty.call(this.currentCOA, 'currency') && this.currentCOA.currency ? this.currentCOA.currency : 'kr'
    }
  },
  methods: {
    loadingDataToast () {
      this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
        title: this.$t('reports.balance.balance_report_over_time.title'),
        variant: 'info',
        solid: true
      })
    },
    handleSectionUpdate (value) {
      this.sections = value
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.loadingDataToast()
      this.fetchApiData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description'), {
            title: this.$t('common.filter_settings_title'),
            variant: 'success',
            solid: true
          })
        })
    },
    applyFilter (eventData) {
      this.resolution = eventData.resolution
      this.start_date = eventData.sDateStart
      this.end_date = eventData.sDateEnd
      this.fetchApiData()
    },
    fetchApiData () {
      this.busyState.chart = true
      this.busyState.table = true
      const sectionsValue = _.flatten([...this.sections].filter((el) => el.model !== 'title' && el.model !== 'description').map((el) => el.value).filter((el) => el))
      const include = sectionsValue.length > 0 ? sectionsValue.join(',') : null
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balancePerMonth?start_date=${this.start_date}&end_date=${this.end_date}&resolution=${this.resolution}`
      if (include && include.length > 0) {
        ApiEndpoint += `&include=${include}`
      }
      return axios.get(ApiEndpoint)
        .then(response => response.data.data)
        .then(responseData => {
          this.apiData = responseData
          this.busyState.chart = false
          this.busyState.table = false
          return true
        })
        .catch(err => {
          console.error(err)
          return false
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab } })
      }
    },
    changeTab (tab) {
      if (['networth', 'asset-totals', 'asset-groups', 'asset-subtypes', 'asset-accounts', 'liability-totals', 'liability-groups', 'liability-subtypes', 'liability-accounts', 'settings'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    }
  },
  watch: {
    start_date: function (newVal) {
      if (this.showToastOnYearChange) {
        this.$bvToast.toast(this.$t('common.toasts.loading_data_for_period', { start_date: this.start_date, end_date: this.end_date }), {
          title: this.$t('reports.performance.savings_ratio.title'),
          variant: 'info',
          solid: true
        })
      } else {
        this.showToastOnYearChange = true
      }

      if (newVal) {
        this.fetchApiData().then(status => {
          this.$bvToast.toast(this.$t('common.toasts.data_for_period_is_now_loaded', { start_date: this.start_date, end_date: this.end_date }), {
            title: this.$t('reports.performance._common.savings_ratio_report'),
            variant: 'success',
            solid: true
          })
        })
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
.balance-report-over-time {
  width: 100%;
  .balance-report-over-time-table-wrapper {
    .card-body {
      padding-top: 0px;
    }
  }
}
</style>
