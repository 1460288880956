<template>
  <b-container fluid class="balance-report-over-time-group-chart-wrapper">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'balance-report-over-time-group-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chroma from 'chroma-js'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportOverTimeGroupChart',
  props: ['apiData', 'currencySymbol', 'busy', 'chartType'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.balance.balance_report_over_time.chart.groups.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      // Custom chart options
      this.chartOptions.legend.display = true
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const suffix = ' ' + currencySymbol

      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return aDatasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const labelData = []
      let aAPIData = ''
      var aDatasets = []
      let aDataset = {}
      let aColors = []
      let primaryColor = null
      let aOriginalsColors = ['#FEC600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c']
      const aGoogleStudioColors = ['#4285f4', '#db4437', '#f4b400', '#0f9d58', '#ab47bc', '#00acc1', '#ff7043', '#9e9d24', '#5c6bc0', '#f06292', '#00796b', '#c2185b', '#7e57c2', '#03a9f4', '#8bc34a', '#8bc34a', '#fdd835', '#fb8c00', '#8d6e63', '#9e9e9e', '#607d8b']
      aOriginalsColors = aOriginalsColors.reverse()

      switch (this.chartType) {
        case 'liability-groups' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_groups') && Object.prototype.hasOwnProperty.call(this.apiData.account_groups, 'liability') ? this.apiData.account_groups.liability : []
          aColors = aOriginalsColors
          primaryColor = '#dda3a2'
          break
        case 'asset-groups' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_groups') && Object.prototype.hasOwnProperty.call(this.apiData.account_groups, 'asset') ? this.apiData.account_groups.asset : []
          aColors = aOriginalsColors
          primaryColor = '#8cccad'
          break
        case 'asset-accounts' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'accounts') && Object.prototype.hasOwnProperty.call(this.apiData.accounts, 'asset') ? this.apiData.accounts.asset : []
          this.chartOptions.legend.display = true
          aColors = aGoogleStudioColors
          primaryColor = '#8cccad'
          break
        case 'asset-subtypes' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_subtypes') && Object.prototype.hasOwnProperty.call(this.apiData.account_subtypes, 'asset') ? this.apiData.account_subtypes.asset : []
          this.chartOptions.legend.display = true
          aColors = aOriginalsColors
          primaryColor = '#8cccad'
          break
        case 'liability-subtypes' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_subtypes') && Object.prototype.hasOwnProperty.call(this.apiData.account_subtypes, 'liability') ? this.apiData.account_subtypes.liability : []
          this.chartOptions.legend.display = true
          aColors = aOriginalsColors
          primaryColor = '#dda3a2'
          break
        case 'liability-accounts' :
          aAPIData = Object.prototype.hasOwnProperty.call(this.apiData, 'accounts') && Object.prototype.hasOwnProperty.call(this.apiData.accounts, 'liability') ? this.apiData.accounts.liability : []
          this.chartOptions.legend.display = true
          primaryColor = '#dda3a2'
          aColors = aOriginalsColors
          break
      }
      const assetsData = Object.prototype.hasOwnProperty.call(this.apiData, 'accounts') && Object.prototype.hasOwnProperty.call(this.apiData.accounts, 'asset') ? this.apiData.accounts.asset : []
      const balances = assetsData.length > 0 ? assetsData[0].balances : {}
      // Fix label data
      for (var yearmonth in balances) {
        labelData.push(yearmonth)
      }

      // Prepare groups as dataset
      for (var index in aAPIData) {
        const tmpData = []

        for (yearmonth in aAPIData[index].balances) {
          tmpData.push(aAPIData[index].balances[yearmonth].toFixed(0))
        }

        let sTitle = aAPIData[index].title
        if (this.chartType === 'asset-subtypes' || this.chartType === 'liability-subtypes') {
          sTitle = this.$t('common.asset_liabilities.subtypes_plural.' + sTitle)
        }

        aDataset = {
          label: sTitle,
          data: tmpData,
          stack: 'stack',
          type: 'bar',
          backgroundColor: chroma(aColors.length > 0 ? aColors.shift() : primaryColor).alpha(0.8).css()
        }
        aDatasets.push(aDataset)
      }

      this.chartData = {
        labels: labelData,
        datasets: aDatasets
      }
    }
  }
}
</script>

<style lang="scss">
  .balance-report-over-time-group-chart-wrapper{

  }
</style>
