<template>
  <b-container fluid class="balance-report-over-time-table-wrapper balance-report-over-time-group-table-wrapper px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <b-row no-gutters>
              <b-col class="col-md-10">
                <i18n tag="h2" :path="translationPath + 'title_' + tab.toLowerCase()">
                  <span slot="period">{{selectedPeriod}}</span>
                </i18n>
                <i18n tag="p" class="col-lg-10 pl-0" :path="translationPath + 'description_' + tab.toLowerCase()">
                  <span slot="period">{{selectedPeriod}}</span>
                </i18n>
              </b-col>
              <b-col class="col-md-2">
                <b-form-select
                  v-model="selectedPeriod"
                  :options="periodItems"
                  :disabled="!isLoaded"
                ></b-form-select>
              </b-col>
            </b-row>
          </template>

          <b-col cols="12" class="px-0 pb-5">
            <b-table class="spirecta-simple-table report-account-table report-account-table-basic mb-0" show-empty hover responsive striped
              stacked="md"
              :items="items"
              :fields="fieldValues"
              :busy="!isLoaded"
            >
              <template v-slot:table-busy>
                <loader></loader>
              </template>

              <template v-slot:cell(title)="row">
                <template v-if="row.item.belongsTo === 'totalRow'">
                  {{ $t('common.' + row.value) }}
                </template>
                <template v-else>
                  <template v-if="['assetGroupsTab', 'liabilityGroupsTab'].indexOf(tab) !== -1">
                    <b-link :to="'/reports/performance/account-groups/' + row.item.id">{{ row.value }}</b-link>
                  </template>
                  <template v-else-if="['assetAccountsTab', 'liabilityAccountsTab'].indexOf(tab) !== -1">
                    <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.value }}</b-link>
                  </template>
                  <template v-else-if="['assetSubtypesTab', 'liabilitySubtypesTab'].indexOf(tab) !== -1">
                    {{ $t('common.asset_liabilities.subtypes_plural.' + (row.value !== '' ? row.value : 'null')) }}
                  </template>
                  <template v-else>{{ row.value }}</template>
                </template>
              </template>

              <template v-slot:cell(period_data)="row">
                <template v-if="selectedPeriod && Object.prototype.hasOwnProperty.call(row.item.balances, selectedPeriod)">
                  {{ amountFormatter(row.item.balances[selectedPeriod]) }}
                </template>
              </template>
            </b-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'BalanceReportOverTimeGroupTable',
  components: { Loader },
  props: ['apiData', 'tab'],
  data () {
    return {
      isLoaded: false,
      items: null,
      periodItems: [],
      selectedPeriod: null,
      translationPath: 'reports.balance.balance_report_over_time.table_group.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'period_data', label: this.$t('common.amount'), class: 'text-right' }
      ]
    }
  },
  created () {
    this.fillTable()
  },
  watch: {
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    tab () {
      this.fillTable()
    }
  },
  methods: {
    fillTable () {
      this.isLoaded = false
      if (this.apiData === null) {
        return false
      }
      this.items = []
      this.periodItems = []

      let apiData = null
      switch (this.tab) {
        case 'asset-groups':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_groups') && Object.prototype.hasOwnProperty.call(this.apiData.account_groups, 'asset') ? this.apiData.account_groups.asset : []
          break
        case 'liability-groups':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_groups') && Object.prototype.hasOwnProperty.call(this.apiData.account_groups, 'liability') ? this.apiData.account_groups.liability : []
          break
        case 'asset-subtypes':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_subtypes') && Object.prototype.hasOwnProperty.call(this.apiData.account_subtypes, 'asset') ? this.apiData.account_subtypes.asset : []
          break
        case 'liability-subtypes':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'account_subtypes') && Object.prototype.hasOwnProperty.call(this.apiData.account_subtypes, 'liability') ? this.apiData.account_subtypes.liability : []
          break
        case 'asset-accounts':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'accounts') && Object.prototype.hasOwnProperty.call(this.apiData.accounts, 'asset') ? this.apiData.accounts.asset : []
          break
        case 'liability-accounts':
          apiData = Object.prototype.hasOwnProperty.call(this.apiData, 'accounts') && Object.prototype.hasOwnProperty.call(this.apiData.accounts, 'liability') ? this.apiData.accounts.liability : []
          break
      }
      if (!apiData) {
        return false
      }

      const totalRow = {
        title: 'total',
        belongsTo: 'totalRow',
        balances: {}
      }

      for (const i in apiData) {
        const item = apiData[i]
        let sTitle = item.title
        if (this.tab === 'asset-subtypes' || this.tab === 'liability-subtypes') {
          sTitle = this.$t('common.asset_liabilities.subtypes_plural.' + sTitle)
        }
        this.items.push({ id: item.id, title: sTitle, balances: item.balances })
        for (const date in item.balances) {
          if (!Object.prototype.hasOwnProperty.call(totalRow.balances, date)) {
            totalRow.balances[date] = 0
          }
          totalRow.balances[date] += item.balances[date]
        }
        if (!this.periodItems.length) {
          this.periodItems = Object.keys(item.balances)
          this.periodItems.reverse()
        }
      }
      if (apiData.length !== 0) {
        this.items.push(totalRow)
      }
      this.selectedPeriod = this.periodItems.length ? this.periodItems[0] : null
      this.isLoaded = true
    },
    amountFormatter (value) {
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
    }
  }
}
</script>

<style lang="scss">
.balance-report-over-time-group-table-wrapper{
  tr:last-child td {
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
    font-weight: 700;
  }
}
</style>
