<template>
  <b-container fluid class="balance-report-over-time-table-wrapper balance-report-over-time-total-table-wrapper px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t(translationPath + 'title') }}</h2>
          </template>
          <p class="col-lg-10 pl-0">{{ $t(translationPath + 'description') }}</p>
          <b-col cols="12" class="px-0 pb-5">
            <b-table class="spirecta-simple-table report-account-table report-account-table-basic mb-0" show-empty hover responsive striped
              stacked="md"
              :items="items"
              :fields="fieldValues"
              :busy="!isLoaded"
            >
              <template v-slot:table-busy>
                <loader></loader>
              </template>
            </b-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'BalanceReportOverTimeTotalTable',
  components: { Loader },
  props: ['apiData'],
  data () {
    return {
      isLoaded: false,
      items: null,
      translationPath: 'reports.balance.balance_report_over_time.table_total.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      return [
        { key: 'date', label: this.$t('common.yearmonth') },
        { key: 'asset_balances', label: this.$t(this.translationPath + 'assets'), formatter: 'amountFormatter', class: 'text-right' },
        { key: 'liability_balances', label: this.$t(this.translationPath + 'liabilities'), formatter: 'amountFormatter', class: 'text-right' },
        { key: 'networth', label: this.$t(this.translationPath + 'networth'), formatter: 'amountFormatter', class: 'text-right td-networth' },
        { key: 'solidity', label: this.$t(this.translationPath + 'solidity'), formatter: 'amountFormatterPercentage', class: 'text-right td-solidity' },
        { key: 'ltv_ratio', label: this.$t(this.translationPath + 'loan_to_value_ratio'), formatter: 'amountFormatterPercentage', class: 'text-right' }
      ]
    }
  },
  created () {
    this.fillTable()
  },
  watch: {
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    tabType () {
      this.fillTable()
    }
  },
  methods: {
    fillTable () {
      this.isLoaded = false
      if (this.apiData === null) {
        return false
      }

      this.items = []
      const oItems = {}
      const props = ['liability_balances', 'asset_balances', 'networth', 'solidity', 'ltv_ratio']
      for (const propIndex in props) {
        const prop = props[propIndex]
        for (const date in this.apiData[prop]) {
          if (!Object.prototype.hasOwnProperty.call(oItems, date)) {
            oItems[date] = { date: date }
          }
          oItems[date][prop] = this.apiData[prop][date]
        }
      }

      for (const o in oItems) {
        this.items.push(oItems[o])
      }
      this.items = this.items.sort(function (a, b) {
        const nameA = a.date
        const nameB = b.date
        return nameB.localeCompare(nameA)
      })
      this.isLoaded = true
    },
    amountFormatter (value) {
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
    },
    amountFormatterPercentage (value) {
      return value + ' %'
    }
  }
}
</script>

<style lang="scss">
.balance-report-over-time-total-table-wrapper{
  td.td-networth{
    font-weight: 900;
  }
  td.td-solidity{
    border-left:1px solid black;
  }
}

</style>
